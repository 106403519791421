import React from "react";

export const EnergyAccessRasterVariables: string[] = []

export const EnergyConsumptionDemandRasterVariables: string[] = []

export const EnergyInfrastructureRasterVariables: string[] = []

export const ModelsRasterVariables: string[] = []

export const ProjectsRasterVariables: string[] = []

export const EnergySourcesRasterVariables: string[] = []