export const CarbonRasterVariables: string[] = []

export const ConsumptionRasterVariables: string[] = []

export const LandRasterVariables: string[] = [
    "Volta Cropping System(sh)"
]

export const OtherEmissionsRasterVariables: string[] = [
    "Geology(sh)"
]

export const PricesRasterVariables: string[] = []

export const ProductionRasterVariables: string[] = []

export const SoilRasterVariables: string[] = [
    "Soils(sh)"
]