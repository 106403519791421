import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { object, string } from 'yup'
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
axios.defaults.withCredentials = true;

type formValues = { email: string; password: string; }

const validationSchema = object().shape({
    email: string().email('Invalid email').required('Email is required'),
    password: string().min(8, 'Password must be at least 8 characters long').required('Password is required'),
});

const Login = () => {
    const navigate = useNavigate();
    const [error, setError] = useState(null)
    const location = useLocation();
    const formik = useFormik<formValues>({
        initialValues: {
            email: "",
            password: "",
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            axios.post(`${process.env.REACT_APP_API_URL}/auth/`, values)
                .then(response => {
                    if (response.data['status'] === "loggedin"){
                        return
                    }
                    if (response.status === 200 && response.data['status'] === "success"){
                        localStorage.setItem("username", response.data['username'])
                        localStorage.setItem("token", response.data['token'])
                        navigate("/sectors", {state: {message: "Logged in successfully"}})
                    } else {
                        setError(response.data['message']);
                    }
                })
                .catch(error => { 
                    alert("Oops, something went wrong, try again later")
                    return
                 })
        },
    })

    useEffect(() => {
        const token = localStorage.getItem('token')
        if (token){
            navigate('/sectors', {state: {message: 'Already logged in'}})
        }
    }, [])

    function handlePasswordEyeToggle() {
        const pwField: HTMLInputElement = document.querySelector("#password")!;
        if (pwField.type === "password") {
            pwField.type = "text";
        } else {
            pwField.type = "password";
        }
    }

    function clear(){
        window.history.replaceState({}, '')
    }

    return (
        <>
            <main>
                <section className="vh-lg-100 mt-5 mt-lg-0 bg-soft d-flex align-items-center">
                    <div className="container">
                        {location.state && <div className="alert alert-info alert-dismissible fade show" role="alert">
                            <strong>{location.state.message}</strong>
                            <button onClick={() => clear()} type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                            </div>}
                        <p className="text-center">
                            <Link to="/" className="d-flex align-items-center justify-content-center">
                                <svg className="icon icon-xs me-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z" clipRule="evenodd"></path></svg>
                                Back to homepage
                            </Link>
                        </p>
                        <div className="row justify-content-center form-bg-image" data-background-lg="/static/assets/img/illustrations/signin.svg">
                            <div className="col-12 d-flex align-items-center justify-content-center">
                                <div className="bg-white shadow border-0 rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                                    {error ? <div className="alert alert-danger text-center" role="alert">{error}</div> : null}
                                    <div className="text-center text-md-center mb-4 mt-md-0">
                                        <h1 className="mb-0 h3">
                                            Login
                                        </h1>
                                    </div>
                                    <form method="post" action="" className="mt-4" onSubmit={formik.handleSubmit}>
                                        <div className="form-group mb-4">
                                            <label htmlFor="email">Email</label>
                                            <div className="input-group">
                                                <span className="input-group-text" id="basic-addon1">
                                                    <span className="fas fa-user-circle">
                                                        <svg className="icon icon-xs" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0L492.8 150.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48H48zM0 176V384c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V176L294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176z" /></svg>
                                                    </span>
                                                </span>
                                                <input
                                                    type="email"
                                                    name="email"
                                                    id="email"
                                                    placeholder="example@mail.com"
                                                    className="form-control"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.email}
                                                />
                                            </div>
                                            {formik.touched.email && formik.errors.email ? (
                                                <div className="alert alert-danger p-1 mt-1" role="alert">
                                                    {formik.errors.email}
                                                </div>
                                            ) : null}
                                        </div>
                                        <div className="form-group">
                                            <div className="form-group mb-4">
                                                <label htmlFor="password">Your Password</label>
                                                <div className="input-group">
                                                    <span className="input-group-text" id="basic-addon2">
                                                        <span className="fas fa-unlock-alt">
                                                            <svg className="icon icon-xs" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><path d="M144 144v48H304V144c0-44.2-35.8-80-80-80s-80 35.8-80 80zM80 192V144C80 64.5 144.5 0 224 0s144 64.5 144 144v48h16c35.3 0 64 28.7 64 64V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V256c0-35.3 28.7-64 64-64H80z" /></svg>
                                                        </span>
                                                    </span>
                                                    <input
                                                        type="password"
                                                        name="password"
                                                        id="password"
                                                        placeholder="password"
                                                        className="form-control"
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.password}
                                                        autoComplete=""
                                                    />
                                                    <div className="input-group-text" data-password="false" onClick={() => handlePasswordEyeToggle()}>
                                                        <Link to='#'>
                                                            <span className="password-eye"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-eye" viewBox="0 0 16 16"><path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"></path><path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"></path></svg></span>
                                                        </Link>
                                                    </div>
                                                </div>
                                                {formik.touched.password && formik.errors.password ? <div className="alert alert-danger p-1 mt-1" role="alert">{formik.errors.password}</div> : null}
                                            </div>
                                            <div className="d-flex justify-content-between align-items-top mb-4">
                                                <div className="form-check">
                                                </div>
                                                <div><Link to="/forgot-password" className="small text-right">Lost password?</Link></div>
                                            </div>
                                        </div>
                                        <div className="d-grid">
                                            <button type="submit" name="login" className="btn btn-gray-800">Sign IN</button>
                                        </div>
                                    </form>
                                    <br />
                                    <div className="d-flex justify-content-center align-items-center mt-4">
                                        <span className="fw-normal">
                                            Not registered?
                                            <Link to="/signup" className="fw-bold"> Create account</Link>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </>
    )
}

export default Login;