import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { 
    CarbonRasterVariables, 
    ConsumptionRasterVariables, 
    LandRasterVariables, 
    OtherEmissionsRasterVariables, 
    PricesRasterVariables, 
    ProductionRasterVariables } from "../../utils/Raster/Variables/Agriculture/AgriculturalRasterVariables";

interface DownloadProps {
    startDate?: Date | null
    endDate?: Date | null
    theType?: String
    layers?: (string | null)[]
}


const downloadRaster = (varList: (string|null)[], fn : React.Dispatch<React.SetStateAction<string>>) => {
    const allVariables = [
        ...CarbonRasterVariables, ...ConsumptionRasterVariables, ...LandRasterVariables, ...OtherEmissionsRasterVariables, ...PricesRasterVariables, ...ProductionRasterVariables,
    ]
    const url = window.location.href.split('/');
    let dataSource;
    if (url[url.length - 1] === ""){
        dataSource = (url[url.length - 2]).replaceAll('_', ' ')
    } else {
        dataSource = (url[url.length - 1]).replaceAll('_', ' ')
    }

    for (let i = 0; i < allVariables.length; i++) {
        if (allVariables[i].startsWith(dataSource)) {
            axios.post(`${process.env.REACT_APP_API_URL}/file/`, {
                "variable_list": varList,
                "data_source_type": allVariables[i].split('(')[1].slice(0, -1)
            }, {
                responseType: "arraybuffer"
            })
            .then((response)=>{
                const contentType = response.headers["content-type"];
                    if (contentType && contentType === 'application/zip'){
                        const url = window.location.href.split('/');
                        let dataSource;
                        if (url[url.length - 1] === ""){
                            dataSource = (url[url.length - 2]).replaceAll('_', ' ')
                        } else {
                            dataSource = (url[url.length - 1]).replaceAll('_', ' ')
                        }
                        const blob = new Blob([response.data], { type: 'application/zip' });
                        const aTag = document.createElement('a');
                        aTag.href = window.URL.createObjectURL(blob);
                        aTag.setAttribute('download', `${dataSource.replaceAll("_", " ")}.zip`);
                        document.body.appendChild(aTag);
                        aTag.click();
                        document.body.removeChild(aTag);
                        return
                    }
                    
                    if(contentType && contentType.includes('application/json')){
                        const jsonResponse = response.data;
                        const error = jsonResponse['error'];
                        fn(error);
                    }
            })
            .catch(error => {
                alert('Oops, something happened, try again later'
                )
                return
            })
            return;
        }
    }
}

export const ExportButton = (props: DownloadProps) => {
    const [error, setError] = useState("")
    const navigate = useNavigate()

    async function handleDownload(cls: string, locName: string) {
        if (document.getElementById('errorDiv')){
            document.getElementById('errorDiv')?.remove()
        };

        if (props.theType === 'raster'){
            if (props.layers?.length! < 1){
                alert("No layer selected");
                return
            }
            downloadRaster(props.layers!, setError)
            return
        }

        if (props.startDate === null || props.endDate === null) {
            alert("Select Time Range");
            return
        }

        if (props.startDate! > props.endDate!) {
            alert("Starting date should not be more than ending date");
            return
        }

        const varList: HTMLCollectionOf<HTMLInputElement> = document.getElementsByClassName(cls) as HTMLCollectionOf<HTMLInputElement>;
        const varArray: Array<any> = []
        Array.from(varList).forEach((item) => {
            if (item.checked) {
                varArray.push(item.name)
            }
        })

        if (varArray.length === 0) {
            alert("No variable selected!")
            return
        }

        const locationList: HTMLCollectionOf<HTMLInputElement> = document.getElementsByClassName(locName) as HTMLCollectionOf<HTMLInputElement>;
        const locationArray: Array<any> = []
        Array.from(locationList).forEach((item) => {
            if (item.checked) {
                locationArray.push(item.name)
            }
        })

        if (locationArray.length === 0) {
            alert("No location selected!")
            return
        }

        try{
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/check/`,
                {
                    params: {
                        token: localStorage.getItem('token')
                    }
                }
            )
            const data = await res.data
            if (data['status'] === "failed"){
                navigate("/login", {state: {message: data['message']}})
            }
        } catch(err){
            alert("Oops, something went wrong")
            return
        }
        
        axios.post(`${process.env.REACT_APP_API_URL}/file/`, {
            "startDate": props.startDate?.toISOString(),
            "endDate": props.endDate?.toISOString(),
            "variables": varArray,
            "locations": locationArray
        }, {
            responseType: "arraybuffer"
        })
        .then((response) => {
            const contentType = response.headers["content-type"];
            if (contentType && contentType === 'application/zip'){
                const blob = new Blob([response.data], { type: 'application/zip' });
                const aTag = document.createElement('a');
                aTag.href = window.URL.createObjectURL(blob);
                aTag.setAttribute('download', `CLEWS Timeseries Data(${props.startDate?.toISOString().split('T')[0]} - ${props.endDate?.toISOString().split('T')[0]}).zip`);
                document.body.appendChild(aTag);
                aTag.click();
                document.body.removeChild(aTag);
                return
            }
            
            if(contentType && contentType.includes('application/json')){
                const jsonResponse = response.data;
                const error = jsonResponse['error'];
                setError(error);
            }
        })
        .catch(error => {
            alert("Oops, something went wrong")
            return
        })
    }

    return (
        <>
            {error ? (
                <button type="button" onClick={() => { handleDownload('varName', 'locName') }} className="btn btn-sm btn-danger d-inline-flex align-items-center">
                    <svg className="icon icon-xs me-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32V274.7l-73.4-73.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0l128-128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L288 274.7V32zM64 352c-35.3 0-64 28.7-64 64v32c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V416c0-35.3-28.7-64-64-64H346.5l-45.3 45.3c-25 25-65.5 25-90.5 0L165.5 352H64zm368 56a24 24 0 1 1 0 48 24 24 0 1 1 0-48z" /></svg>
                    {error}
                </button>
            ) : (
                <button type="button" onClick={() => { handleDownload('varName', 'locName') }} className="btn btn-sm btn-secondary d-inline-flex align-items-center">
                    <svg className="icon icon-xs me-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32V274.7l-73.4-73.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0l128-128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L288 274.7V32zM64 352c-35.3 0-64 28.7-64 64v32c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V416c0-35.3-28.7-64-64-64H346.5l-45.3 45.3c-25 25-65.5 25-90.5 0L165.5 352H64zm368 56a24 24 0 1 1 0 48 24 24 0 1 1 0-48z" /></svg>
                    Export
                </button>
            )}
        </>
    )
}