
export const WaterAccessRasterVariables: string[] = [
    "Volta Subbasins(sh)"
]

export const AquiferSystemsRasterVariables: string[] = [
    "Piezometry(sh)"
]

export const DamsRasterVariables: string[] = [
    "Africa Dams(sh)",
    "Black Volta Dams(sh)"
]

export const DemandRasterVariables: string[] = []

export const EcosystemsRasterVariables: string[] = [
    "Hydrography(sh)",
    "Wet Area(sh)",
    "Water Bodies(sh)"
]

export const LakesRasterVariables: string[] = [
    "Tana and Volta Basin(sh)",
    "Volta Basin Main Rivers(sh)",
    "Volta Subbasins(sh)",
]

export const SourcesRasterVariables: string[] = []

export const WaterConsumptionRasterVariables: string[] = []

export const WatershedsRasterVariables: string[] = []